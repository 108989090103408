<template>
  <div class="_bg-default _80w _100vh pa-3">
    <form @submit.prevent="update">
      <v-card class="radius-card pa-3 _100top mb-3">
        <v-card-title>
          <h3>Settings</h3>
          <v-divider></v-divider>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="person.data.username"
            prepend-icon="mdi-account"
            label="Username"
          ></v-text-field>
          <v-text-field
            :error-messages="emailErr"
            v-model="person.data.email"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            type="Email"
            prepend-icon="mdi-at"
            label="email"
          ></v-text-field>
          <v-text-field
            :error-messages="PasswordError"
            v-model="password"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show = !show"
            prepend-icon="mdi-lock"
            label="Password"
          ></v-text-field>
          <v-text-field
            :error-messages="PwdConfirmError"
            v-model="passwordConfirm"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            @click:append="show2 = !show2"
            prepend-icon="mdi-lock"
            label="Repeat Password"
            @input="$v.passwordConfirm.$touch()"
            @blur="$v.passwordConfirm.$touch()"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" type="update" v-if="!loading" dark>SAVE</v-btn>
          <v-btn class="mb-3" color="purple" dark v-if="loading">
            <v-progress-circular
              :width="5"
              color="white"
              indeterminate
              class="mr-2"
            ></v-progress-circular>
            <b> LOADING ... </b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { minLength, sameAs, email } from "vuelidate/lib/validators";

export default {
  name: "Settings",
  mixins: [validationMixin],
  validations: {
    email: { email },
    password: { minLength: minLength(8) },
    passwordConfirm: { sameAsPassword: sameAs("password") }
  },
  computed: {
    ...mapState({
      result: state => state.psycholog.solveMsg,
      errMsg: state => state.psycholog.errMsg
    }),
    emailErr() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Email tidak valid!");
      return errors;
    },
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      return errors;
    },
    PwdConfirmError() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      !this.$v.passwordConfirm.sameAsPassword &&
        errors.push("kata sandi tidak sama!");
      return errors;
    }
  },
  data() {
    return {
      person: null,
      password: "",
      passwordConfirm: "",
      show: false,
      show2: false,
      loading: false
    };
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    fetchUser() {
      let id = this.$route.params.id;
      this.$store.dispatch("viewPsycholog", id).then(data => {
        this.person = data;
      });
    },
    update() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        let id = this.$route.params.id;
        let data = null;
        if (this.password == "") {
          data = {
            username: this.person.data.username,
            email: this.person.data.email
          };
        } else {
          data = {
            username: this.person.data.username,
            email: this.person.data.email,
            password: this.password
          };
        }
        this.loading = true;
        this.$store
          .dispatch("psycholog/updateAkun", { id: id, data: data })
          .then(() => {
            this.alert("success", this.result.message);
            this.$emit("success");
            this.password = "";
            this.passwordConfirm = "";
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            for (let i in err) {
              this.alert("error", `${err[i][0]}`);
            }
          });
      }
    },
    alert(type, title, msg) {
      this.$notify({
        group: "authVerification",
        type: type,
        title: title,
        text: msg,
        duration: 8000
      });
    }
  }
};
</script>

<style></style>
